define('pbr-client/services/authentication-garena', ['exports', 'pbr-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        initialize: function initialize() {
            return new Ember.RSVP.Promise(function (resolve) {
                Ember.$('head').append('<link rel="stylesheet" type="text/css" href="assets/garena.css">');
                resolve();
            });
        },
        isAuthenticated: function isAuthenticated() {
            return !!this.get('cookie').getCookie('GARENA_ACCESS_TOKEN') || undefined;
        },
        getAccountId: function getAccountId() {
            return 0;
        },
        /**
         * Returns the default region that should be used for the page, or undefined
         * @returns {*}
         */
        getDefaultRegion: function getDefaultRegion() {
            return undefined;
        },
        /**
         * Returns the default language/locale that should be used, or undefined
         * @returns {*|undefined}
         */
        getDefaultLanguage: function getDefaultLanguage() {
            return undefined;
        },
        login: function login() /*queryParamString*/{
            var locale = Ember.$.cookie('pbr_locale');
            var garenaLocale = _environment.default.APP.GARENA_LOCALE_MAPPINGS[locale] || '';
            // Redirect users back to base url after login
            window.location = _environment.default.APP.OAUTH_URL + '&locale=' + garenaLocale;
        }
    });
});