define('pbr-client/components/text-field-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.TextField.extend({
        value: Ember.computed.alias('field.value'),
        name: Ember.computed.alias('field.name'),
        maxlength: Ember.computed.alias('field.maxLength'),
        required: Ember.computed.alias('field.required'),
        'classNames': undefined,
        init: function init() {
            this.set('classNames', ['input-text', 'form-control']);
            this._super();
        }
    });
});