define('pbr-client/components/textarea-field-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var DEFAULT_VALUE = '\u200B';
    exports.default = Ember.TextArea.extend({
        i18n: Ember.inject.service(),
        name: Ember.computed.alias('field.name'),
        value: '',
        required: Ember.computed.alias('field.required'),
        init: function init() {
            this._super.apply(this, arguments);
            this.set('classNames', ['input-text', 'form-control']);
            this.set('classNameBindings', ['isPlaceholder:placeholder']);

            var fieldValue = this.get('field.value'),
                fieldPlaceholder = this.get('field.placeholder');
            if (fieldValue && fieldValue !== '') {
                this.set('value', fieldValue);
            } else if (fieldPlaceholder) {
                this.set('value', fieldPlaceholder);
            }
        },
        isPlaceholder: Ember.computed('value', function () {
            var value = this.get('value'),
                fieldPlaceholder = this.get('field.placeholder');
            return value === fieldPlaceholder || value === DEFAULT_VALUE;
        }),
        valuechange: Ember.observer('value', function () {
            var value = this.get('value'),
                fieldPlaceholder = this.get('field.placeholder');
            if (value !== fieldPlaceholder && value !== DEFAULT_VALUE) {
                value = value.replace(DEFAULT_VALUE, '');
                this.set('field.value', value);
            }
        }),
        'focusIn': function focusIn() {
            "use strict";

            var value = this.get('value'),
                fieldPlaceholder = this.get('field.placeholder');
            if (value === fieldPlaceholder) {
                this.set('value', DEFAULT_VALUE);
            }
        },
        'focusOut': function focusOut() {
            "use strict";

            var value = this.get('value'),
                fieldPlaceholder = this.get('field.placeholder');
            if (value === DEFAULT_VALUE) {
                this.set('value', fieldPlaceholder);
            }
        }
    });
});