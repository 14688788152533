define('pbr-client/services/authentication-none', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        initialize: function initialize() {
            return new Ember.RSVP.Promise(function (resolve) {
                resolve();
            });
        },
        isAuthenticated: function isAuthenticated() {
            return true;
        },
        getAccountId: function getAccountId() {
            return 0;
        },
        /**
         * Returns the default region that should be used for the page, or undefined
         * @returns {*}
         */
        getDefaultRegion: function getDefaultRegion() {
            return 'all';
        },
        /**
         * Returns the default language/locale that should be used, or undefined
         * @returns {*|undefined}
         */
        getDefaultLanguage: function getDefaultLanguage() {
            return 'en_US';
        },
        login: function login() {
            // Perform login action
        }
    });
});