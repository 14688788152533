define('pbr-client/components/select-field-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    exports.default = Ember.Component.extend({
        i18n: Ember.inject.service(),
        tagName: 'select',
        name: Ember.computed.alias('field.name'),
        required: Ember.computed.alias('field.required'),
        attributeBindings: ['name', 'value', 'required'],
        value: Ember.computed.alias('field.value'),
        change: function change() {
            this.set('field.value', this.$().val());
        },
        formattedOptions: Ember.computed('field.options', function () {
            var formattedOptions = [{ value: '', label: '' }],
                fieldName = this.get('field.name'),
                options = this.get('field.options');

            for (var i = 0; i < options.length; i++) {
                var option = options[i],
                    value = (typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object' ? option.value : option,
                    name = (typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object' ? option.name : null,
                    label = (typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object' ? option.label : option;

                if (name != null) {
                    label = name;
                } else {
                    label = this.get('i18n').t('fields.' + fieldName + '.options.' + label);
                }

                var optionGroupOptions = null;
                if (option.options) {
                    // Option group
                    optionGroupOptions = [];
                    for (var j = 0; j < option.options.length; j++) {
                        var optionValue = option.options[j],
                            optionLabel = this.get('i18n').t('fields.' + fieldName + '.options.' + option.options[j]);
                        optionGroupOptions.push({ value: optionValue, label: optionLabel });
                    }
                }

                formattedOptions.push({
                    value: value,
                    label: label,
                    options: optionGroupOptions
                });
            }

            return formattedOptions;
        })
    });
});