define('pbr-client/models/form-field', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        init: function init() {
            "use strict";

            this.set('inputVisible', !this.get('toggle'));
        },

        type: null,
        name: null,
        defaultValue: null,
        value: null,
        required: false,
        multiple: false,
        maxLength: false,
        visible: true,
        inputVisible: true,
        placeholder: null,
        toggle: false,
        options: undefined,
        error: Ember.computed('visible', 'value', function () {
            var value = this.get('value'),
                required = this.get('required'),
                visible = this.get('visible');
            if (visible) {
                if (required && value === '') {
                    return 'validation.required';
                }
            }
            return null;
        })
    });
});