define('pbr-client/components/korea-toolbar', ['exports', 'pbr-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        logoutUrl: _environment.default.APP.LOGOUT_URL,
        isAuthenticated: Ember.computed(function () {
            return this.get('authentication').isAuthenticated();
        }),
        i18n: Ember.inject.service()
    });
});