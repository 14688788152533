define('pbr-client/routes/locale/bug-report/create', ['exports', 'pbr-client/config/environment', 'pbr-client/models/bug-report-form'], function (exports, _environment, _bugReportForm) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        i18n: Ember.inject.service(),
        beforeModel: function beforeModel() {
            "use strict";

            var localizedData = this.modelFor('locale');
            if (!localizedData.loggedIn && _environment.default.APP.LOGIN_REQUIRED) {
                this.router.transitionTo('locale.login', { queryParams: localizedData.queryParams });
            }
        },
        model: function model(params, transition) {
            "use strict";

            var localizedData = this.modelFor('locale'),
                gameData = localizedData.gameData;

            var title = this.get('i18n').t('title'),
                supportDisclaimer = this.get('i18n').t('support_disclaimer'),
                clickHere = this.get('i18n').t('click_here'),
                supportURL = _environment.default.APP.SUBMIT_A_REQUEST_URL.replace('{LOCALE}', localizedData.locale.split('_')[0].toLowerCase());

            var bugReportForm = new _bugReportForm.default(gameData, this.get('i18n'), transition.queryParams, localizedData.knownIssues);

            return {
                title: title,
                submitText: this.get('i18n').t('buttons.submit'),
                supportDisclaimer: supportDisclaimer,
                clickHere: clickHere,
                supportURL: supportURL,
                localizedData: localizedData,
                form: bugReportForm,
                localeData: this.modelFor('locale')
            };
        }
    });
});