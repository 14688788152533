define('pbr-client/components/form-field', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        i18n: Ember.inject.service(),
        classNames: ['bug-report-field'],
        classNameBindings: ['visible'],
        visible: Ember.computed.alias('field.visible'),
        type: Ember.computed.alias('field.type'),
        error: Ember.computed.alias('field.error'),
        inputVisibleClass: Ember.computed('field.inputVisible', function () {
            return this.get('field.inputVisible') ? 'visible' : 'hidden';
        }),
        labelText: Ember.computed('field', 'field.{toggle,inputVisible}', function () {
            var label = null;
            if (this.get('field.toggle') && !this.get('field.inputVisible')) {
                label = this.get('i18n').t('fields.' + this.get('field.name') + '.toggleLabel');
            }
            if (label === null || label === '') {
                label = this.get('i18n').t('fields.' + this.get('field.name') + '.label');
            }
            return label;
        }),
        helpText: Ember.computed('field', function () {
            return this.get('i18n').t('fields.' + this.get('field.name') + '.defaultValue');
        }),
        inputTemplate: Ember.computed('field', function () {
            return this.get('field.type') + '-field-input';
        })
    });
});