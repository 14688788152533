define('pbr-client/models/bug-report-form', ['exports', 'lodash', 'pbr-client/models/form-field'], function (exports, _lodash, _formField) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        formFields: null,
        init: function init(gameData, i18n, queryParams, knownIssues) {
            "use strict";

            this.formFields = this.getDefaultFormFields();
            var i,
                form = {},
                fieldInstances = [];

            for (i = 0; i < this.formFields.length; i++) {
                var item = this.formFields[i];
                item.id = i;
                item.inputVisible = !item.toggle;

                if (queryParams[item.name]) {
                    item.value = queryParams[item.name];
                } else if (item.defaultValue) {
                    item.placeholder = '' + i18n.t('fields.' + item.name + '.defaultValue');
                }

                var obj = _formField.default.create(item);
                form[item.name] = obj;
                this.set(item.name, obj);
                fieldInstances.push(obj);
            }

            this.set('knownIssues', knownIssues);
            this.set('fields', fieldInstances);
            this.locationChange();
            this.calculateKnownIssues();
        },
        getDefaultFormFields: function getDefaultFormFields() {
            return [{
                "type": "hidden",
                "name": "bugType",
                "visible": false,
                "value": "standard"
            }, {
                "type": "hidden",
                "name": "system_os",
                "visible": false,
                "value": ""
            }, {
                "type": "hidden",
                "name": "app_version",
                "visible": false,
                "value": ""
            }, {
                "type": "select",
                "name": "location",
                "required": true,
                "visible": true,
                "options": ["pre_client", "client", "champ_select", "in_game"]
            }, {
                "type": "select",
                "name": "type",
                "required": false,
                "visible": false,
                "options": []

            }, {
                "type": "select",
                "name": "known_issue",
                "showEmpty": true,
                "visible": false,
                options: []
            }, {
                "type": "text",
                "name": "summary",
                "required": true,
                "maxLength": 255,
                "visible": true
            }, {
                "type": "textarea",
                "name": "description",
                "defaultValue": true,
                "required": false,
                "showHelpText": true,
                "visible": true
            }];
        },

        /**
         * Update type fields when the location changes
         */
        locationChange: Ember.observer('location.value', function () {

            var location = this.get('location.value'),
                typeOptions = [],
                typeVisible = true;

            switch (location) {
                case 'pre_client':
                    typeOptions = ["pre_client_install", "pre_client_login", "pre_client_patching"];
                    break;
                case 'client':
                    typeOptions = ["client_store", "client_loot", "client_profile", "client_collection", "client_eog", "client_friends", "client_other"];
                    break;
                case 'champ_select':
                    typeOptions = ["champ_select_wards", "champ_select_emotes", "champ_select_skins", "champ_select_lockin", "champ_select_runes", "champ_select_other"];
                    break;
                case 'in_game':
                    typeOptions = ['in_game_loading', 'in_game_map', 'in_game_champion', 'in_game_item', 'in_game_shop', 'in_game_ui', 'in_game_skin', 'in_game_other'];
                    break;
                default:
                    typeVisible = false;
            }

            this.set('type.options', typeOptions);
            this.set('type.visible', typeVisible);
        }),
        calculateKnownIssues: Ember.observer('location.value', 'type.value', function () {
            "use strict";

            var knownIssues = this.get('knownIssues'),
                displayedKnownIssues = [],
                type = this.get('type.value'),
                location = this.get('location.value');

            // Location, type, and tags must all be satisfied
            _lodash.default.filter(knownIssues, function (knownIssue) {
                if (knownIssue.location !== undefined && knownIssue.location !== null && knownIssue.location !== location) {
                    return false;
                } else if (knownIssue.type !== undefined && knownIssue.type !== null && knownIssue.type !== type) {
                    return false;
                }

                displayedKnownIssues.push(knownIssue);
            });
            this.set('known_issue.visible', displayedKnownIssues.length > 0);
            this.set('known_issue.options', displayedKnownIssues);
        })
    });
});