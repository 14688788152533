define('pbr-client/routes/index', ['exports', 'lodash', 'pbr-client/config/environment'], function (exports, _lodash, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel(transition) {
            var authentication = this.get('authentication');

            var region = Ember.$.cookie('pbr_region') || _environment.default.APP.DEFAULT_REGION,
                lang = Ember.$.cookie('pbr_locale') || _environment.default.APP.DEFAULT_LOCALE;

            if (_environment.default.APP.USE_RIOTBAR !== false) {
                region = authentication.getDefaultRegion() || region;
                lang = authentication.getDefaultLanguage() || lang;
            }

            // validate region and locale
            if (_environment.default.APP.LOCALES[region] === undefined || !_lodash.default.isArray(_environment.default.APP.LOCALES[region]) || _environment.default.APP.LOCALES[region].indexOf(lang) === -1) {

                // If the region is valid, find the best default locale
                if (_lodash.default.isArray(_environment.default.APP.LOCALES[region])) {
                    var defaultLocale = _environment.default.APP.LOCALES[region][0];
                    if (lang && lang.indexOf('_')) {
                        var parts = lang.split('_');
                        // See if we can find a closer match - ex if its es_MX prefer es_AR
                        // Currently a bug where RiotBar sets cookies incorrectly for LAS to es_MX
                        _lodash.default.each(_environment.default.APP.LOCALES[region], function (locale) {
                            if (locale.indexOf(parts[0]) === 0) {
                                defaultLocale = locale;
                            }
                        });
                    }
                    lang = defaultLocale;
                } else {
                    region = _environment.default.APP.DEFAULT_REGION;
                    lang = _environment.default.APP.DEFAULT_LOCALE;
                }
            }

            this.router.transitionTo('/' + region + '/' + lang + '/bugReport/create?' + Ember.$.param(transition.queryParams));
        }
    });
});