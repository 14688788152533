define('pbr-client/controllers/application', ['exports', 'pbr-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        isGarena: Ember.computed(function () {
            return _environment.default.APP.AUTHENTICATION === 'garena';
        }),
        isKorea: Ember.computed(function () {
            return _environment.default.APP.AUTHENTICATION === 'korea';
        }),
        isTesting: Ember.computed(function () {
            return _environment.default.APP.AUTHENTICATION === 'none';
        })
    });
});