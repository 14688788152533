define("pbr-client/auth/riot", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        initialize: function initialize() {
            console.log("Initialize triggered...");
        },
        isAuthenticated: function isAuthenticated() {
            console.log("Is authenticated called");
        }
    });
});