define('pbr-client/services/authentication-korea', ['exports', 'pbr-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        initialize: function initialize() {
            return new Ember.RSVP.Promise(function (resolve) {
                Ember.$('head').append('<link rel="stylesheet" type="text/css" href ="https://fonts.googleapis.com/earlyaccess/notosanskr.css">' + '<link rel="stylesheet" type="text/css" href="assets/kr.css">');

                resolve();
            });
        },
        isAuthenticated: function isAuthenticated() {
            return this.get('cookie').getCookie('PVPNET_ID_KR') && this.get('cookie').getCookie('PVPNET_TOKEN_KR');
        },
        getAccountId: function getAccountId() {
            return this.get('cookie').getCookie('PVPNET_ID_KR');
        },
        login: function login() {
            // Redirect users back to base url after login
            window.location = _environment.default.APP.LOGIN_URL;
        },
        /**
         * Returns the default region that should be used for the page, or undefined
         * @returns {*}
         */
        getDefaultRegion: function getDefaultRegion() {
            return 'kr';
        },
        /**
         * Returns the default language/locale that should be used, or undefined
         * @returns {*|undefined}
         */
        getDefaultLanguage: function getDefaultLanguage() {
            return 'ko_KR';
        }
    });
});