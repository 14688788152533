define('pbr-client/components/text-multiple-field-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        id: 0,
        init: function init() {
            this._super();
            var value = this.get('field.value');
            if (!value) {
                value = [{ id: this.id++, name: '' }];
            } else if (!(value instanceof Array)) {
                value = [{ id: this.id++, name: value }];
            }
            this.set('values', value);
        },
        values: undefined,
        onValuesChange: Ember.observer('values.@each.name', function () {
            var str = this.get('values').map(function (val) {
                return val.name;
            }).join(',');
            this.set('field.value', str);
        }),
        removeLinkClass: Ember.computed('values.[]', function () {
            return this.get('values').length <= 1 ? 'hidden' : '';
        }),
        actions: {
            addInput: function addInput() {
                this.get('values').pushObject({ id: this.id++, name: '' });
            },
            removeInput: function removeInput(val) {
                console.log("Remove Input: ", this, arguments);
                this.get('values').removeAt(val);
            }
        }
    });
});