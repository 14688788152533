define('pbr-client/initializers/cookie-injector', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: 'cookie-initializer',
        after: ['cookie'],
        initialize: function initialize(app) {
            "use strict";

            app.inject('route', 'cookie', 'cookie:main');
            app.inject('service', 'cookie', 'cookie:main');
        }
    };
});