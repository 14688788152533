define('pbr-client/controllers/locale/bug-report/create', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            submit: function submit() {

                var fields = this.get('model.form.fields'),
                    localeData = this.get('model.localeData'),
                    valid = true,
                    that = this,
                    i;

                for (i = 0; i < fields.length; i++) {
                    var fieldValue = fields[i].get('value');
                    // Force value update if it hasnt already been touched
                    if (fieldValue === null || fieldValue === undefined) {
                        fields[i].set('value', '');
                    }

                    if (fields[i].get('error') !== null) {
                        valid = false;
                    }
                }

                if (valid) {
                    var data = {
                        region: localeData.region,
                        locale: localeData.locale
                    };

                    for (i = 0; i < fields.length; i++) {
                        data[fields[i].name] = fields[i].get('value');
                    }
                    this.set('submitting', true);

                    $.ajax({
                        url: '/bugs/create',
                        data: JSON.stringify(data),
                        type: 'POST',
                        contentType: 'application/json',
                        dataType: 'json',
                        success: function success() {
                            "use strict";

                            that.transitionToRoute('locale.bugReport.thanks');
                        },
                        error: function error(jqXHR) {
                            "use strict";

                            if (jqXHR.status === 429) {
                                that.set('statusMessage', "errors.rate_limit");
                            } else {
                                that.set('statusMessage', "errors.timeout");
                            }
                        },
                        complete: function complete() {
                            that.set('submitting', false);
                        }
                    });
                }
            }
        }

    });
});