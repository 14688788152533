define('pbr-client/components/garena-toolbar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        logoutUrl: '/logout',
        i18n: Ember.inject.service(),
        router: Ember.inject.service(),
        isAuthenticated: Ember.computed('authentication', function () {
            return this.get('authentication').isAuthenticated();
        }),
        init: function init() {
            this._super.apply(this, arguments);
            this.set('languages', [{ id: "en_PH", label: "Philippines - English" }, { id: "en_SG", label: "Singapore - English" }, { id: "id_ID", label: "Bahasa Indonesia" }, { id: "ms_MY", label: "Bahasa Melayu" }, { id: "th_TH", label: "ภาษาไทย" }, { id: "vn_VN", label: "Tiếng Việt" }, { id: "zh_MY", label: "中文（简体）" }, { id: "zh_TW", label: "中文（繁體）" }]);
            this.set('language', this.get('i18n.locale'));
        },
        language: '',
        onLanguageChange: Ember.observer('language', function () {
            var language = this.get('language');
            this.set('i18n.locale', language);
            this.router.transitionTo('/garena/' + language + '/bugReport/create');
        }),
        onLocaleChanged: Ember.observer('i18n.locale', function () {
            var locale = this.get('i18n.locale');
            this.set('language', locale);
        }),
        actions: {
            changeLocale: function changeLocale(language) {
                this.set('language', language);
            }
        }
    });
});