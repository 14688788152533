define('pbr-client/controllers/locale/login', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            login: function login() {
                return this.get('authentication').login(this.get('model').queryParamString);
            }
        }

    });
});