define('pbr-client/auth/garena', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        initialize: function initialize() {},
        isAuthenticated: function isAuthenticated() {}
    });
});