define('pbr-client/initializers/authentication', ['exports', 'pbr-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;

    //import authenticationRiot from 'pbr-client/services/authentication-riot.js';
    //import authenticationGarena from 'pbr-client/services/garena.js';

    function initialize(application) {
        var authenticationProvider = 'service:authenticationNone';

        if (_environment.default.APP.AUTHENTICATION === 'riot') {
            authenticationProvider = 'service:authenticationRiot';
        } else if (_environment.default.APP.AUTHENTICATION === 'qq') {
            authenticationProvider = 'service:authenticationQq';
        } else if (_environment.default.APP.AUTHENTICATION === 'garena') {
            authenticationProvider = 'service:authenticationGarena';
        } else if (_environment.default.APP.AUTHENTICATION === 'korea') {
            authenticationProvider = 'service:authenticationKorea';
        }

        application.inject('route', 'authentication', authenticationProvider);
        application.inject('controller', 'authentication', authenticationProvider);
        application.inject('component', 'authentication', authenticationProvider);
    }

    exports.default = {
        isServiceFactory: true,
        name: 'authentication',
        initialize: initialize
    };
});