define('pbr-client/router', ['exports', 'pbr-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('oauth');
    this.route('locale', { path: '/:locale/' });
    this.route('locale', { path: '/:region/:locale' }, function () {
      this.route('login');
      this.route('bugReport', function () {
        this.route('create', { path: '/create' });
        this.route('thanks');
      });
    });
  });

  exports.default = Router;
});