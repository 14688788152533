define('pbr-client/services/authentication-riot', ['exports', 'pbr-client/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        initialize: function initialize(params) {
            if (_environment.default.APP.USE_RIOTBAR) {
                return new Ember.RSVP.Promise(function (resolve) {
                    var script = document.getElementById('riotbar');
                    if (script === null && !window.RiotBar) {

                        document.addEventListener("riotbar_onshowcomplete", function () {
                            // Now the global window.RiotBar object is available to you, but not everything
                            resolve();
                        });

                        Ember.$('<script type="text/javascript" id="riotbar"  src="https://cdn.rgpub.io/public/live/riotbar/latest/pbr-lol.' + params.locale + '.js"></script>').appendTo(document.head);
                    } else {
                        // RiotBar already exists
                        resolve();
                    }
                });
            } else {
                return new Ember.RSVP.Promise(function (resolve) {
                    resolve();
                });
            }
        },
        isAuthenticated: function isAuthenticated() {
            var rb = window.RiotBar;
            if (rb) {
                var authState = rb.account.getAuthState();
                if (authState && authState.isAuthenticated) {
                    return true;
                }
            }
            return false;
        },
        getAccountId: function getAccountId() {
            var rb = window.RiotBar;
            return rb ? rb.account.getPvpnetId() : 0;
        },
        login: function login(queryParamString) {
            // Redirect users back to base url after login
            window.history.pushState({}, '', location.origin + queryParamString);
            var rb = window.RiotBar;
            if (rb) {
                rb.account.login();
            }
        },
        /**
         * Returns the default region that should be used for the page, or undefined
         * @returns {*}
         */
        getDefaultRegion: function getDefaultRegion() {
            var rb = window.RiotBar;
            if (rb) {
                return rb.account.getRegion();
            } else {
                return undefined;
            }
        },
        /**
         * Returns the default language/locale that should be used, or undefined
         * @returns {*|undefined}
         */
        getDefaultLanguage: function getDefaultLanguage() {
            var rb = window.RiotBar;
            if (rb) {
                return rb.account.getLocale();
            } else {
                return undefined;
            }
        }
    });
});