define('pbr-client/routes/locale/login', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            "use strict";

            var model = this.modelFor('locale');
            if (model.loggedIn) {
                this.router.transitionTo('locale.bugReport.create', { queryParams: model.queryParams });
            }
        }
    });
});